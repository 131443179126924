<template>
  <div class="statusReportContainerFlexColumn">
    <div class="statusReportContainerFlexColumn__top">
      <div class="title">状态时长详情</div>
      <el-divider />
      <div class="buttons">
        <el-button type="primary" @click="back">返回</el-button>
      </div>
    </div>
    <div class="statusReportContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams">
        <el-table-column prop="suser" width="120" label="客服名称" align="center"></el-table-column>
        <el-table-column prop="cphone" width="120" label="手机号" align="center"></el-table-column>
        <el-table-column prop="ntime" width="120" label="时长" align="center">
          <template #default="scope">
            {{ scope.row.ntime | getTimeFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="pstatus" label="状态" align="center">
          <template #default="scope">
            {{ scope.row.pstatus | getStatusText }}
          </template>
        </el-table-column>
        <el-table-column prop="tcreate" label="创建时间" align="center">
          <template #default="scope">
            {{ scope.row.tcreate | dateFilter }}
          </template>
        </el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import { Message } from "element-ui";
import moment from "moment";

export default {
  name: "StatusReport",
  filters: {
    dateFilter(dateString) {
      // 示例时间字符串

      // 创建一个 Date 对象
      const date = new Date(dateString);

      // 获取各部分
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，所以要加1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // 格式化为更易读的格式
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getTimeFormat(val) {
      let h = parseInt(val / 3600);
      h = h > 9 ? `${h}` : `0${h}`;
      let m = parseInt((val - 3600 * h) / 60);
      m = m > 9 ? `${m}` : `0${m}`;
      let s = val - 3600 * h - 60 * m;
      s = s > 9 ? `${s}` : `0${s}`;
      return `${h}:${m}:${s}`;
    },
    getStatusText(status) {
      if (status === "-1") return "退出登录";
      else if (status === "0") return "登录CTI成功";
      else if (status === "2") return "座席就绪(示闲)";
      else if (status === "3") return "座席忙碌(示忙)";
      else if (status === "4") return "座席外拨";
      else if (status === "5") return "座席振铃";
      else if (status === "7") return "座席挂机处于空闲状态";
      else if (status === "8") return "座席挂机处于后处理状态(整理态)";
      else if (status === "10") return "座席接通呼入电话";
      else if (status === "11") return "座席接通呼出电话";
      else if (status === "12") return "座席正在咨询中";
      else if (status === "13") return "座席在会议中";
      else if (status === "14") return "用户处于保持中";
      else if (status === "16") return "座席正在监听中";
      else if (status === "17") return "座席正在辅助中!";
      else if (status === "18") return "座席正在强插中";
      else if (status === "20") return "座席外呼,对方正在振铃";
      else if (status === "21") return "座席咨询,对方正在振铃";
      else if (status === "22") return "桥接中，座席处于保持状态";
      else if (status === "23") return "座席外呼中!";
      else if (status === "24") return "座席内呼中!";
      else if (status === "25") return "咨询失败，用户保持状态";
      else if (status === "26") return "外呼后座席振铃前状态";
      else if (status === "27") return "内部后座席振铃前状态";
    },
  },
  props: {},
  data() {
    return {
      apiUrl: `${this.baseUrls.callDataCount}/statusReportDetail`,
      queryParams: {},
      selected: [
        // { pstatus: "-1", name: "没有初始化" },
        { pstatus: "", name: "全部" },
        { pstatus: "-1", name: "退出登录" },
        { pstatus: "0", name: "登录CTI成功" },
        { pstatus: "2", name: "座席就绪(示闲)" },
        { pstatus: "3", name: "座席忙碌(示忙)" },
        { pstatus: "4", name: "座席外拨" },
        { pstatus: "5", name: "座席振铃" },
        { pstatus: "7", name: "座席挂机处于空闲状态" },
        { pstatus: "8", name: "座席挂机处于后处理状态(整理态)" },
        { pstatus: "10", name: "座席接通呼入电话" },
        { pstatus: "11", name: "座席接通呼出电话" },
        { pstatus: "12", name: "座席正在咨询中" },
        { pstatus: "13", name: "座席在会议中" },
        { pstatus: "14", name: "用户处于保持中" },
        { pstatus: "16", name: "座席正在监听中" },
        { pstatus: "17", name: "座席正在辅助中!" },
        { pstatus: "18", name: "座席正在强插中" },
        { pstatus: "20", name: "座席外呼,对方正在振铃" },
        { pstatus: "21", name: "座席咨询,对方正在振铃" },
        { pstatus: "22", name: "桥接中，座席处于保持状态" },
        { pstatus: "23", name: "座席外呼中!" },
        { pstatus: "24", name: "座席内呼中!" },
        { pstatus: "25", name: "咨询失败，用户保持状态" },
        { pstatus: "26", name: "外呼后座席振铃前状态" },
        { pstatus: "27", name: "内部后座席振铃前状态" },
      ],
      n_count_total: 0,
      n_time_total: 0,
    };
  },
  created() {
    this.resetPara();
  },
  mounted() {},
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        ruser: this.$route.query.userId,
        pstatus: this.$route.query.status,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
      };
    },
    back() {
      this.$router.push({ path: "/call/statusReport" });
    },
  },
};
</script>
<style lang="scss">
.statusReportContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }

    .buttons {
      text-align: right;
      clear: both;
    }
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }
}
</style>

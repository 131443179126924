var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "statusReportContainerFlexColumn" }, [
    _c(
      "div",
      { staticClass: "statusReportContainerFlexColumn__top" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("状态时长详情")]),
        _c("el-divider"),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.back } },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "statusReportContainerFlexColumn__body" },
      [
        _c(
          "dc-datatable",
          { ref: "list", attrs: { url: _vm.apiUrl, params: _vm.queryParams } },
          [
            _c("el-table-column", {
              attrs: {
                prop: "suser",
                width: "120",
                label: "客服名称",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "cphone",
                width: "120",
                label: "手机号",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "ntime",
                width: "120",
                label: "时长",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("getTimeFormat")(scope.row.ntime)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "pstatus", label: "状态", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("getStatusText")(scope.row.pstatus)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "tcreate", label: "创建时间", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dateFilter")(scope.row.tcreate)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }